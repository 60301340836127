import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import get from 'lodash/get'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { ArticleJsonLd } from 'gatsby-plugin-next-seo';
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import PageTitle from '../components/PageTitle'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Posts extends React.Component {
           //LOAD MORE
           constructor(props) {
            super(props);
            this.state = {
              items: [],
              visible: 20,
              error: false,
            };
            this.loadMore = this.loadMore.bind(this);
            this.showLess = this.showLess.bind(this);
          }
          loadMore() {
            this.setState((prev) => {
              return { visible: prev.visible + 20 };
            });
          }
          showLess() {
            this.setState((prev) => {
              return { visible: 20 };
            });
          }
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
    var lisa = document.getElementsByClassName("tab-lisa");
		var bonus = document.getElementsByClassName("tab-bonus");
		var i;
		for (i = 0; i < lisa.length; i++) {
			lisa[i].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[0].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
					this.nextElementSibling.classList.remove("active");
					this.nextElementSibling.nextElementSibling.classList.remove("active");
				}
			});
		}
		var j;
		for (j = 0; j < bonus.length; j++) {
			bonus[j].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[1].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
					this.nextElementSibling.classList.remove("active");
					this.previousElementSibling.classList.remove("active");
				}
			});
		}					
		var tahdet = document.getElementsByClassName("tahdet");
		var t;
		for (t = 0; t < tahdet.length; t++) {
			var two = "<img src='/images/star2.webp' alt='2' class='lazyload'/>"
			var twohalf = "<img src='/images/star2half.webp' alt='2' class='lazyload'/>"
			var three = "<img src='/images/star3.webp' alt='3' class='lazyload'/>"
			var threehalf = "<img src='/images/star3half.webp' alt='3' class='lazyload'/>"
			var four = "<img src='/images/star4.webp' alt='4' class='lazyload'/>"
			var fourhalf = "<img src='/images/star4half.webp' alt='4' class='lazyload'/>"
			var five = "<img src='/images/star5.webp' alt='5' class='lazyload'/>"
			var rate = tahdet[t].innerHTML;
			var star = rate.replace("2", two).replace("Two and half", twohalf).replace("3", three).replace("3 and half", threehalf).replace("4", four).replace("4 and half", fourhalf).replace("5", five);
			tahdet[t].innerHTML = star;
		}
  }
  render() {
    const data = get(this, 'props.data.contentfulFrontpage')
    return(
      <Layout>
      <ArticleJsonLd
      url='https://listadecasinos.net/'
      headline='Lista De Casinos Online | Argentina'
      images={[
        'https://listadecasinos.net/images/listadecasinos.webp',
      ]}
      datePublished='2024-01-04'
      dateModified='2024-01-04'
      authorName='Javier Garcia'
      description='Lista de Casinos Online | Argentina'
      publisherName='Lista de Casinos'
      publisherLogo='https://listadecasinos.net/images/listadecasinos.webp'
      overrides={{
        '@type': 'techArticle', 
        'profiencyLevel':'Expert'
      }}
    />
    <GatsbySeo
      title='Lista de Casinos Online | Argentina'
      description='Los mejores casinos online en Argentina. ¡Bienvenido a los mejores casinos en línea en Argentina: que comiencen los juegos!'  
    />
     <FAQJsonLd
        questions={[
          { question: '¿Son legales los casinos online en Argentina? ', answer: 'En cada región de Argentina, incluyendo las provincias y la capital federal, existen regulaciones específicas relacionadas con el juego online. Nuestra recomendación es que siempre elijas casinos online con licencia. Esto garantiza que el casino opere de manera legal y proporcione un entorno seguro para los jugadores.' },
          { question: '¿Cómo jugar casino online?', answer: 'Participar en casinos en línea es una de las actividades más comunes en la actualidad para aumentar tus ganancias en internet. Estos casinos se encuentran al lado del trading, y en nuestra opinión, actualmente son más rentables que el Forex o cualquier método similar.' },
        ]}
      />
      <Container>
      <PageTitle>Lista de Casinos Online</PageTitle>
      <div className="ylaosa">{documentToReactComponents(data.ylosanTeksti1.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <center><h2>	&#x1f1e6;&#x1f1f7; Los mejores casinos online en Argentina 	&#x1f1e6;&#x1f1f7;</h2></center>
      <table>
        <tbody>
          {data.etusivuBonuslista .slice(0, this.state.visible) .map(({transparentLogo,name,afflink,casinodetails,bonusfield,freeSpins}) => (
          <tr className={casinolistStyle.casino}>
            <td className={casinolistStyle.top}>
              <div className={casinolistStyle.logo}>
                <img data-src={transparentLogo.file.url} alt={transparentLogo.title} width="180px" height="94px" className="lazyload"/>
                <div className={casinolistStyle.name}>
                  <b><p className={casinolistStyle.title}>{name}</p></b>
                </div>
              </div>
              <div className={casinolistStyle.content}>
                <div className={casinolistStyle.contentBox}>
                  <div className={casinolistStyle.contentInfo}>
                    <div className={casinolistStyle.offerItem}>
                      <i>Bonos</i>
                      <h4><div>{bonusfield.argentinaBonus}</div></h4>
                    </div>
                    <div className={casinolistStyle.offerItem}>
                      <i>Giros Gratis</i>
                      <h4>{freeSpins.argentinaFs}</h4>
                    </div>
                  </div>
                  <div className={casinolistStyle.contentBtn}>
                    <a className={casinolistStyle.btn} href={afflink} target="_blank">¡Jugá ahora!</a>
                  </div>
                </div>
              </div>
            </td>
            <td className={casinolistStyle.bottom}>
              <div className={casinolistStyle.pikaexpand}>
              {documentToReactComponents(casinodetails.json)}
              </div>
            </td>
          </tr>
          ))}
        </tbody>
      </table>
      <div className="loadBtn">
                    {this.state.visible > 20 && (
                      <button
                        onClick={this.showLess}
                        type="button"
                        id="showLess"
                      >
                        Menos
                      </button>
                    )}
                    {this.state.visible < data.etusivuBonuslista.length && (
                      <button
                        onClick={this.loadMore}
                        type="button"
                        id="loadMore"
                      >
                        Más casinos
                      </button>
                    )}
                  </div>
      <div>
        <h2>Preguntas frecuentes</h2>
        <div>
          <div><h5>¿Son legales los casinos online en Argentina? </h5></div>
          <p>En cada región de Argentina, incluyendo las provincias y la capital federal, existen regulaciones específicas relacionadas con el juego online. Nuestra recomendación es que siempre elijas casinos online con licencia. Esto garantiza que el casino opere de manera legal y proporcione un entorno seguro para los jugadores.</p>
        </div>
        <div>
          <div><h5>¿Cómo jugar casino online?</h5></div>
          <p>Participar en casinos en línea es una de las actividades más comunes en la actualidad para aumentar tus ganancias en internet. Estos casinos se encuentran al lado del trading, y en nuestra opinión, actualmente son más rentables que el Forex o cualquier método similar.</p>
        </div>                          
      </div>
      </Container>
    </Layout>
    )
  }
}

export default Posts

export const query = graphql`
query($skip: Int!, $limit: Int!) {
  allContentfulPost(
    sort: { fields: [publishDate], order: DESC }
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        title
        id
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          ogimg: resize(width: 1800) {
            src
          }
        }
        body {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 80)
          }
        }
      }
    }
  }
    contentfulFrontpage(title: {eq: "Frontpage"}) {
      title
      id
      slug
      ylosanTeksti1 {
        json
      }
      etusivuBonuslista {
        name
        transparentLogo {
          title
          file {
            url
          }
        }
        afflink
        bonusfield {
          argentinaBonus
        }
        freeSpins {
          argentinaFs
        }
        casinodetails {
          json
          }
        bonusdetails {
          json
        }
      }
    }
  }
`

